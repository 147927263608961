<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 1147 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1146.14 0H0V1146.14H1146.14V0Z" fill="#008F99" />
    <path
      d="M429.553 536.051L353.331 406.167H390.64C465.343 406.167 475.418 440.059 475.418 473.207C475.418 508.698 461.559 527.996 429.553 536.011M546.933 473.207C546.933 423.994 526.637 341.47 390.64 341.47H236.671L352.606 540.267H249.195V804.633H320.705V604.973H390.228L505.438 804.633H585.692L463.629 594.346C517.559 575.972 546.918 533.677 546.918 473.246"
      fill="white"
    />
    <path
      d="M610.81 538.803V804.481H682.816V603.361H782.134C853.442 603.361 911.248 544.313 911.248 473.005C911.248 401.697 853.442 342.649 782.134 342.649H610.81V407.206H777.168C810.08 407.206 836.759 433.886 836.759 466.797V479.212C836.759 512.124 810.08 538.803 777.168 538.803H610.81Z"
      fill="white"
    />
  </svg>
</template>
